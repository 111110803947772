export const question = {
  list: '问卷调查列表',
  from: '问卷调查操作',
  view: '查看问卷调查',
  create: '新建问卷调查',

  basic_info: '基本信息',
  question_info: '试题信息',
  answer_info: '答案信息',

  info: '试题信息',
  title: '试题标题',
  description: '试题描述',
  url: '试题资源',
  type: '试题类型',
  content: '试题内容',
  answer: '试题答案',
  status: '试题状态',
  create_time: '添加时间',

  type: '类型',
  level: '难度',
  score: '分值',
  content: '题目内容',
  category: '分类',
  optionA: '选项A',
  optionB: '选项B',
  optionC: '选项C',
  optionD: '选项D',
  optionE: '选项E',
  optionF: '选项F',
  answer: '参考答案',
  analysis: '试题解析',
  placeholder_input_option: '请先输入选项再添加',

  choices: '选择题',
  judgement: '判断题',
  complete: '填空题',
  explain: '名词解析题',
  connection: '连线题',

  correct: '正确',
  error: '错误',

  rules: {
    title: {
      require: '试题标题不能为空',
    },
    options: {
      require: '试题内容不能为空',
    },
    answer: {
      require: '试题答案不能为空',
    },
  },

  option: {
    create: '新增',
    title: '答案序号',
    value: '答案内容',
    url: '答案资源地址',
    result: '选项结果',
    position: '正确位置',
  }
}
