export const client = {
  list: '客户列表',
  from: '客户操作',
  view: '客户详情',
  transfer: '客户转移',

  basic_info: '基本信息',
  contact_info: '联系人信息',
  demand_info: '需求信息',
  trace_info: '跟踪记录',
  browse_info: '访问记录',

  statistics_info: '统计信息',
  company_info: '企业信息',
  history_info: '历史信息',

  info: '客户信息',
  tool: '查询工具',
  name: '客户名称',
  level: '客户级别',
  scale: '客户规模',
  share_tag: '共享标记',
  industry_id: '客户行业',
  contact_name: '联系人姓名',
  contact_sex: '联系人性别',
  contact_mobile: '联系人电话',
  contact_position: '联系人职位',
  is_demand: '是否有需求',
  demand_type: '需求类型',
  demand_time: '需求时间',
  description: '需求描述',
  new_demand: '最近需求',
  new_record: '最近追踪',
  integrity_score: '信息完整度',
  current_username: '当前归属人',
  batch_transfer: '批量转移',

  rules: {
    name: {
      require: '客户名称不能为空',
    },
    level: {
      require: '客户级别不能为空',
    },
    scale: {
      require: '客户规模不能为空',
    },
    industry_id: {
      require: '客户行业不能为空',
    },
    contact_name: {
      require: '联系人姓名不能为空',
    },
    contact_sex: {
      require: '联系人性别不能为空',
    },
    contact_mobile: {
      require: '联系电话不能为空',
    },
    company_type: {
      require: '企业性质不能为空'
    },
    registered_capital: {
      require: '注册资金不能为空'
    },
    founding_time: {
      require: '成立时间不能为空'
    },
    user_id: {
      require: '销售负责人不能为空'
    },
    client_id: {
      require: '客户名称不能为空'
    },
  },

  detail: {
    client_id: '客户编号',
    integrity_score: '完整性分数',
    company_type: '企业性质',
    registered_capital: '注册资金',
    founding_time: '成立时间',
    industry_ranking: '行业排名',
    company_url: '企业网址',
    company_address: '企业地址',
    company_description: '企业介绍',
    is_up: '是否上市',
  },

  history: {
    integrity_score: '完整性分数',
    demand_person: '需求对接人',
    people_total: '活动人数',
    activity_crowd: '活动人群',
    activity_address: '活动地点',
    activity_journey: '活动行程',
    activity_overview: '活动基调',
    competitor: '竞争对手',
    procurement_form: '采购形式',
    activity_effect: '活动效果',
    opportunity: '参与机会',
    remark: '备注',
  },

  trace: {
    list: '追踪列表',
    from: '追踪操作',
    view: '追踪详情',

    type: '追踪方式',
    contact_id: '联系人',
    content: '沟通内容',
    reminder_date: '提醒日期',

    rules: {
      type: {
        require: '追踪方式不能为空',
      },
      contact_id: {
        require: '联系人不能为空',
      },
      content: {
        require: '沟通内容不能为空',
      },
    }
  },

  share: {
    from: '客户共享',
  }
}
