export const supplier = {
  list: '供应商列表',
  from: '供应商操作',
  view: '供应商详情',
  info: '供应商信息',

  basic_info: '基本信息',
  detail_info: '详细信息',
  resource_info: '供应商附件',

  organization_id: '创建者',
  category_id: '供应商分类',
  title: '供应商名称',
  address: '所属区域',
  cooperation_level: '合作等级',
  remark: '备注',
  content: '详细内容',
  resource: '附件列表',
  create_time: '添加时间',
  is_recommend: '首页推荐',
  status: '供应商状态',
  preview: '供应商预览',
  close: '供应商复制',

  rules: {
    category_id: {
      require: '供应商分类不能为空',
    },
    title: {
      require: '供应商名称不能为空',
    },
    content: {
      require: '详细内容不能为空',
    },
    address: {
      require: '所属区域不能为空',
    },
    cooperation_level: {
      require: '合作等级不能为空',
    },
  },

  category: {
    list: '分类列表',
    from: '分类操作',
    view: '分类详情',
    info: '分类',

    title: '分类标题',
    picture: '封面图片',

    rules: {
      title: {
        require: '分类标题不能为空',
        length: '分类标题最大长度为50',
      },
    }
  },


  contact: {
    list: '联系人列表',
    from: '联系人操作',
    view: '联系人详情',
    info: '供应商联系人',

    name: '联系人姓名',
    mobile: '联系电话',
    position: '职位/角色',

    rules: {
      name: {
        require: '联系人姓名不能为空',
        length: '联系人姓名最大长度为50',
      },
      mobile: {
        require: '联系电话不能为空',
        length: '联系电话最大长度为50',
      },
    }
  },

  product: {
    list: '产品列表',
    from: '产品操作',
    view: '产品详情',
    info: '供应商产品',
    import: '批量导入',
    download: '模板下载',

    title: '产品名称',
    unit: '单位',
    money: '参考单价',
    remark: '备注',

    rules: {
      title: {
        require: '产品名称不能为空',
        length: '产品名称最大长度为50',
      },
    }
  },
}
