export const template = {
  list: '模板列表',
  from: '模板操作',

  title: '模板标题',
  url: '模板文件',

  rules: {
    title: {
      require: '模板标题不能为空',
    },
    url: {
      require: '模板文件不能为空',
    },
  },
}
